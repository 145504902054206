import React, { useState, useContext, useEffect } from 'react'
import {
  Hero,
  Bids,
  Top_collection,
  Tranding_category,
  NewseLatter,
} from '../../components/component'
import Meta from '../../components/Meta'
import NaturalLanguagePlan from '../../components/plan/naturallanguageplan'
import AllInOnePlan from '../../components/plan/allinone'
import SpecificPlans from '../../components/plan'
import NaturalLanguageFeatures from '../../components/features/nl-detector-feature'
import { UserContext } from '../../components/UserContext'

const Home_1 = () => {
  const [loginStatus, setloginStatus] = useState(false)
  const { getSession, logout } = useContext(UserContext)

  useEffect(() => {
    getSession()
      .then(session => {
        setloginStatus(true)
      })
      .catch(err => {
        setloginStatus(false)
      })
  }, [])

  return (
    <main>
      <Meta title='Home' />
      <Hero />
      <NewseLatter />
      <NaturalLanguageFeatures />
      {/* <AllInOnePlan loginStatus={loginStatus} />
      <SpecificPlans loginStatus={loginStatus} /> */}

      {/* <NaturalLanguagePlan /> */}
      {/* <Bids />
      <Top_collection />
      <Tranding_category />
      <NewseLatter /> */}
    </main>
  )
}

export default Home_1
